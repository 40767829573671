import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Process,
  Toolset,
  Involvement,
  Visit,
  TitleContent,
  ExternalLink,
  WorkedWith,
} from "../components/parts"

const Info = ({ link, src, label, style = {}, className = "" }) => (
  <div>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={src} alt="infographics" className={className} style={style} />
    </a>
    <ExternalLink
      label={`Read full Infographics on ${label}`}
      href={link}
      style={{ width: "100%", marginBottom: 32 }}
    />
  </div>
)

const Learnographics = () => (
  <Layout title="Learnographics">
    <Title heading="Learnographics" sub="Read great books pictorially" />
    <Overview
      project="learnographics"
      text={[
        "Learographics are visual summaries of our favorite books into infographics.",
        "I contributed to the graphic design and the illustrations of the infographics.",
      ]}
    />
    <TitleContent title="Infographics">
      <Info
        link="https://learnographics.netlify.app/decision-making.html"
        src="/images/learnographics/3.png"
        label="Decision making"
        className="bordered"
      />
      <Info
        link="https://learnographics.netlify.app/personal-finance.html"
        src="/images/learnographics/2.jpg"
        label="Personal finance"
      />
      <Info
        link="https://learnographics.netlify.app/willpower.html"
        src="/images/learnographics/1.jpg"
        label="Willpower"
      />
    </TitleContent>
    <Process>
      <img src="/images/learnographics/process.png" alt="process" />
    </Process>
    <Toolset project="learnographics" />
    <Involvement project="learnographics" />
    <Visit project="learnographics" />
    <WorkedWith project="learnographics" />
  </Layout>
)

export default Learnographics
